import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import DesktopNavigation from './DesktopNavigation';
import MobileNavigation from './MobileNavigation';
import { NavigationProps, NavigationQuery } from './models';

export const Navigation = ({ closeMenu, isMobileMenuShown, langProps }: NavigationProps) => {
  const {
    allHeader: { nodes },
  }: NavigationQuery = useStaticQuery(graphql`
    {
      allHeader {
        nodes {
          lang
          navigation {
            properties {
              link {
                url
              }
              name
              subItem {
                properties {
                  link {
                    url
                  }
                  name
                }
              }
            }
          }
          lastTwoBreakingPoint
          moreButtonLabel
          seeAllLabel
        }
      }
    }
  `);

  const { navigation, lastTwoBreakingPoint, moreButtonLabel, seeAllLabel } = nodes.find(
    ({ lang }) => langProps.lang === lang
  )!;

  return (
    <>
      <MobileNavigation
        items={navigation}
        closeMenu={closeMenu}
        isMobileMenuShown={isMobileMenuShown}
        seeAllLabel={seeAllLabel}
      />

      <DesktopNavigation
        items={navigation}
        lastTwoBreakingPoint={lastTwoBreakingPoint}
        moreButtonLabel={moreButtonLabel}
        seeAllLabel={seeAllLabel}
      />
    </>
  );
};

export default Navigation;

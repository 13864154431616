import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';
import { useId } from 'react-id-generator';
import { graphql, Link, useStaticQuery } from 'gatsby';

import { DangerouslySetInnerHtml } from 'layout';
import DropdownLanguage from 'components/DropdownLocation';
import dropdownLocationProps from 'components/DropdownLocation/mock.json';
import { DropdownLocationProps } from 'components/DropdownLocation/models.d';
import SocialLinks from 'components/Social';

import { FooterProps, FooterQuery } from './models';

import './Footer.scss';

export const Footer: FC<FooterProps> = ({ langProps }) => {
  const {
    allFooter: { nodes },
  }: FooterQuery = useStaticQuery(graphql`
    {
      allFooter {
        nodes {
          lang
          socialLinks {
            properties {
              url {
                name
                url
              }
              type
            }
          }

          navbar {
            properties {
              label
              url {
                url
                target
              }
            }
          }
          additionalLinks {
            properties {
              label
              url {
                url
                target
              }
            }
          }
          copyright
          additionalLinksTitle
        }
      }
    }
  `);
  const { navbar, copyright, socialLinks, additionalLinks, additionalLinksTitle } = nodes.find(
    ({ lang }) => langProps.lang === lang
  )!;
  const currentYear = new Date().getFullYear();
  const copyrightNotice = copyright.replace(/<p>/g, `<p>© ${currentYear} `);

  return (
    <footer className="footer">
      <div className="footer__container">
        <Container fluid="lg">
          <div className="footer__nav">
            <ul>
              {navbar.map(({ properties }) => {
                const { label, url } = properties;
                const [footerItem] = url;
                const [key] = useId();

                return (
                  <li className="footer__nav--link-box" key={key}>
                    <Link
                      to={footerItem.url}
                      className="footer__link"
                      data-testid="footer-link"
                      target={footerItem.target}
                      {...(footerItem.target && { rel: 'noopener noreferrer' })}
                    >
                      {label}
                    </Link>
                  </li>
                );
              })}
            </ul>
            {additionalLinks?.length ? (
              <div className="footer__nav--additional-links">
                {additionalLinksTitle ? (
                  <p className="footer__nav--additional-title">{additionalLinksTitle}</p>
                ) : null}
                <ul className="footer__nav--additional-list">
                  {additionalLinks.map(({ properties }) => {
                    const { label, url } = properties;
                    const [footerItem] = url;
                    const [key] = useId();

                    return (
                      <li className="footer__nav--link-box" key={key}>
                        <Link
                          to={footerItem.url}
                          className="footer__link"
                          data-testid="additional-footer-link"
                          target={footerItem.target}
                          {...(footerItem.target && { rel: 'noopener noreferrer' })}
                        >
                          {label}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : null}
            <div className="footer__link--lang-selector">
              {/* TODO remove 'false' condition and '<div />' element after Country Selector return in this place in phase 2 */}
              {false && <DropdownLanguage {...(dropdownLocationProps as DropdownLocationProps)} />}
              <SocialLinks socialLinks={socialLinks} />
            </div>
          </div>

          <div className="footer__copyright">
            <DangerouslySetInnerHtml html={copyrightNotice} />
          </div>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
